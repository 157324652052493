export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backIcon = require("../assets/backIcon.png");
export const lightThemeBg = require("../assets/lightBg.png");
export const darkThemeBg = require("../assets/darkBg.png");
export const checkedImage = require("../assets/checked.png");
export const radioButtonChecked = require("../assets/radioChecked.png");
export const radioButtonUncheck = require("../assets/radioUncheck.png");
export const view=require('../assets/view_.svg')
export const darkView=require('../assets/dark_view.svg')
export const RightIcon=require('../assets/RightIcon.svg')
export const paymentLogo=require('../assets/paymentlogo.png')
export const lightBackground=require("../assets/lightBackground.png");
export const darkBackground=require("../assets/darkBackground.png");
export const backArrowWhite=require("../assets/backArrow-white.png");
export const backArrowDark=require("../assets/backArrow-dark.png");