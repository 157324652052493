import React from 'react'
import {
    Typography,
    Box,
    Button,
    Card,
    CardContent,
    IconButton,
    styled,
    TextField,
    withStyles,
    Switch,
    createTheme
} from "@material-ui/core";
import { radioButtonChecked, radioButtonUncheck, RightIcon, paymentLogo } from "./assets";
import { Props } from './PaymentWeb.web';

const StyledIcon = styled(IconButton)({
    padding: 0,
    marginLeft: -4,
    "&.MuiIconButton-root:hover": {
        background: 'unset'
    }
})
interface MyProps extends Props {

}
const PaymentComponent = (props: MyProps) => {
    const classStyles = props.classes || {}
    const formatDate = (inputDate: string) => {
        const date = new Date(inputDate);
        console.log(inputDate,"inputDateinputDate")

        const options = { day: '2-digit', month: 'short', year: 'numeric' };

        return date.toLocaleDateString('en-GB', options as any).replace(',', '');
    }
    return (
        <>
            <Box className={`${classStyles.cardsContainer} ${classStyles.addPaymentContainer}`}>
                <Typography className={classStyles.paymentName}>Payment method</Typography>
                <Button className={`${classStyles.addNewBtn} ${classStyles.feature}`}>Add new</Button>
            </Box>
            <Box className={classStyles.cardsContainer}>
                {props.subscriptionDetails?.slice(0, 2).map((subscription) =>
                    <Card variant="outlined" key={subscription.id} className={subscription.attributes.subscribed ? classStyles.activeCard : classStyles.card}>
                        <CardContent>
                            <img data-test-id={`radioButton${subscription.id}`} className={classStyles.radioImage} onClick={() => props.handleSelectSubscription(subscription.id)} src={subscription.attributes.subscribed ? radioButtonChecked : radioButtonUncheck} />
                            <Box className={classStyles.paymentCardContainer}>
                                <img data-test-id={`paymentLogo${subscription.id}`} className={classStyles.paymentLogo} height={14} width={42} src={paymentLogo} />
                                <Box>
                                    <Typography className={classStyles.subscriptionName}>***1232</Typography>
                                    <Typography className={`${classStyles.subscriptionPrice} ${classStyles.paymentMethodName}`}>Visa | Edit</Typography>

                                </Box>
                            </Box>

                        </CardContent>
                    </Card>
                )}
                <Box className={classStyles.cardsContainer}>
                    <Typography>Payments</Typography>
                    <Card variant="outlined" className={classStyles.card}>
                        <CardContent style={{ gap: '12px', display: 'flex', flexDirection: 'column' }}>
                            <Box className={classStyles.paymentContainer}>
                                <Typography className={classStyles.paymentText}>Plan renew date</Typography>
                                <Typography className={classStyles.paymentText}>{formatDate(props.orderSummary.plan_renew_date)}</Typography>
                            </Box>
                            <Box className={classStyles.paymentContainer}>
                                <Typography className={classStyles.paymentText}>Type of plan</Typography>
                                <Typography className={classStyles.paymentText}>{props.orderSummary.plan_type || "Type"}</Typography>
                            </Box>
                            <Box className={classStyles.paymentContainer}>
                                <Typography className={classStyles.paymentText}>Amount</Typography>
                                <Typography className={classStyles.paymentText}>{props.orderSummary.plan_amount}</Typography>
                            </Box>

                        </CardContent>
                    </Card>
                </Box>
                <Card variant="outlined" className={classStyles.activeCard}>
                    <CardContent>
                        <Box className={classStyles.promoCodeContainer}>
                            <TextField className={classStyles.promoInput} variant="standard" InputProps={{
                                disableUnderline: true,
                            }} onChange={(e: any) => props.onPromoCodeChange(e.target.value)} value={props.promoCode} data-test-id='promo-code-id' />
                            <Button className={classStyles.applyBtn} onClick={props.applyPromoCode} disabled={!props.promoCode} data-test-id='promo-apply'>Apply</Button>
                        </Box>

                    </CardContent>
                </Card>
                <Typography style={{ color: props.promoCodeDetail.isValid ? 'green' : 'red', marginTop: '16px' }}>{props.promoCodeDetail.msg}</Typography>
                <Card variant="outlined" className={classStyles.card}>
                    <CardContent>
                        <div className="cardHeader">
                            <span className="automaticRenewals" style={{ color: "#FFFFFF" }}>Automatic renewals</span>
                            <IOSSwitch data-test-id='switch' onChange={(event) => { props.autoRenewal(event) }} checked={props.isAutoRenewal} />
                        </div>
                    </CardContent>
                </Card>
            </Box>

            <Button
                onClick={props.subscribeUserAPI}
                data-test-id='submit'
                disabled={props.activeId === 0}
                className={classStyles.button}
                fullWidth
                endIcon={<StyledIcon><img src={RightIcon} /></StyledIcon>}
            >Payment
            </Button></>
    )
}
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
        },
    }

});

const IOSSwitch = withStyles(({
    root: {
        width: 48,
        height: 28,
        padding: 0,
    },

    switchBase: {
        padding: 0,
        transform: "translate(4px,4.4px)",
        "&$checked": {
            transform: "translate(23px,4.4px)",
            color: theme.palette.common.white,
            "& + $track": {
                background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
                opacity: 1,
                border: "none",
            },
        },

    },
    thumb: {
        width: 24,
        height: 24,
        margin: "-2px -1px"
    },
    track: {
        borderRadius: 26 / 2,
        backgroundColor: "#1F1F1F",
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
}))(Switch);
export default PaymentComponent;