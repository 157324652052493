import React, { useState } from "react";
import { Box, styled, Typography } from "@material-ui/core";
import { CUSTOM_MESSAGE_DATA_TYPE, CUSTOM_MESSAGE_TYPE, ICustomMessageData, sendCustomMessage, unblockUsers } from "./CometChat";
import clsx from "clsx";
import BlockModal from "./Chats/BlockModal.web";
import { handleConditionFunction, TAny } from "./utils";

interface IPropCometChatBubbleViewAlert {
    isBlockedByMe: boolean;
    message: CometChat.BaseMessage;
    userInfo: CometChat.User | null;
    checked?: boolean;
    onUnblockUser?: () => void;
}

interface ICastMessage {
    customData: ICustomMessageData;
    receiverId: string;
}

export default function CometChatBubbleViewAlert({
    message,
    userInfo,
    isBlockedByMe,
    checked,
    onUnblockUser 
}: IPropCometChatBubbleViewAlert) {
    const [isOpenModalBlock, setIsOpenModalBlock] = useState(false);
    const { customData, receiverId = "" } = message as unknown as ICastMessage;
    const senderName = message.getSender().getName();
    const senderUid = message.getSender().getUid();
    const receiver = message.getReceiver();
    const { name: receiverName = "", metadata: receiverMetadata = { username: "" } } = receiver as TAny;
    const isTypeBlock = customData?.type === CUSTOM_MESSAGE_DATA_TYPE.BLOCK;
    const currentUserLogin = userInfo?.getUid();

    const handleUnBlockUser = async (receiverId: string) => {
        const customData = {
            message: "You unblock this Person.",
            type: CUSTOM_MESSAGE_DATA_TYPE.UNBLOCK
        }
        const customType = CUSTOM_MESSAGE_TYPE.CUSTOM_ACTION;
        await unblockUsers([receiverId]);
        await sendCustomMessage(receiverId, customData, customType);
        if(onUnblockUser) onUnblockUser();
        setIsOpenModalBlock(false);
    }

    const handleRenderMessage = (message?: string) => {
        if (currentUserLogin === senderUid) {
            if (!message) return handleConditionFunction(isTypeBlock, "You unblock this Person.", "You block this Person tap to unblock")
            return message;
        }
        if (!isTypeBlock) {
            return `${senderName} unblocked you`
        }
        return `${senderName} blocked you`
    }

    return (
        <CometChatBubbleViewAlertStyled>
            <BlockModal isOpen={isOpenModalBlock}
                checked={!checked} userName={receiverMetadata?.username || `@${receiverName}`}
                onClose={() => { setIsOpenModalBlock(false) }} onSubmit={() => { handleUnBlockUser(receiverId) }} isBlock={true} />
            <Box
                className={`container ${clsx(
                    checked && "lightTheme",
                    isTypeBlock && "pointer"
                )}`}
                onClick={() => {
                    if (isTypeBlock && isBlockedByMe)
                        setIsOpenModalBlock(true)
                }}
            >
                <Typography component="p" className={`alert_message-text`}>
                    {handleRenderMessage(customData?.message)}
                </Typography>
            </Box>
        </CometChatBubbleViewAlertStyled>
    )
}

const CometChatBubbleViewAlertStyled = styled(Box)({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    "& .pointer": {
        cursor: "pointer",
    },
    "& .container": {
        padding: "6px 10px",
        background: "#7575751F",
        borderRadius: "6px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "auto",
        width: "max-content",
        border: "0",
        "& .alert_message-text": {
            color: "#757575",
            fontFamily: "Manrope",
            fontWeight: 500,
            fontSize: "10px",
        }
    },
    "& .container.lightTheme": {
        background: "#E9E9E9",
        "& .alert_message-text": {
            color: "#222222",
        }
    },
});
