// Customizable Area Start

import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player';

interface VideoPlayerProps {
	url: string
	classes: string
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ url, classes }) => {
	const [playing, setPlaying] = useState(false);
	const videoRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (!entry.isIntersecting) {
					setPlaying(false);
				}
			},
			{
				threshold: 0.1,
			}
		);

		if (videoRef.current) {
			observer.observe(videoRef.current);
		}

		return () => {
			if (videoRef.current) {
				observer.unobserve(videoRef.current);
			}
		};
	}, [videoRef]);


	return (
		<div ref={videoRef}>
			<ReactPlayer
				url={url}
				playing={playing}
				controls={true}
				loading="lazy"
				className={classes}
				onPlay={() => setPlaying(true)}
			/>
		</div>
	);
}

export default VideoPlayer


// Customizable Area End

