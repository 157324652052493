import { profile } from "../../blocks/chat/src/assets";
import clsx from 'clsx';
import React, { useEffect, useState } from "react";
import { Box, Button, Popover, styled, Typography } from "@material-ui/core";
import { CometChatUIKitConstants } from "@cometchat/chat-uikit-react";
import CometChatBubbleViewReaction from "./CometChatBubbleViewReaction";
import CometChatBubbleViewListReaction from "./CometChatBubbleViewListReaction";
import {
    addReactionToMessage,
    deleteMessage,
    fetchListReactionByMessage,
    IReaction,
    ReactionItem,
    ReactionObject,
    removeReactionToMessage
} from "./CometChat";
import { convertTimestampToTime, emitterEvents } from "./utils";
import { EventSubscription } from "fbemitter";
import MessageOptionItem from "./MessageOptionItem.web";
import DeleteMessageModal from "./Chats/DeleteMessageModal.web";
const menuOptionMessage = require("./assets/menuOptionMessage.svg");

interface IPropCometChatBubbleViewTemplate {
    message: CometChat.BaseMessage,
    typeTemplate: string
    userInfo: CometChat.User | null;
    categories: string;
    checked?: boolean;
    onRefreshMessageList?: () => void;
    onOpenModalTotalReaction?: (listReaction: IReaction[]) => void;
}

interface IPropMessageRenderer {
    message: CometChat.BaseMessage;
    categories?: string
}

function CometChatBubbleViewTemplate({
    message,
    typeTemplate,
    userInfo,
    categories,
    checked,
    onRefreshMessageList,
    onOpenModalTotalReaction
}: IPropCometChatBubbleViewTemplate) {
    const [isMouseOver, setIsMouseOver] = useState(false);
    const [isOpenModalConfirmDeleteMessage, setIsOpenModalConfirmDeleteMessage] = useState(false);
    const [currentListReaction, setCurrentListReaction] = useState<ReactionObject[]>([]);
    const [currentListReactionRaw, setCurrentListReactionRaw] = useState<IReaction[]>([]);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [isOpenPopOver, setIsOpenPopOver] = useState(false);

    let subscription: EventSubscription | null = null;
    const currentUserLoginUid = userInfo?.getUid() || "";
    const { uid: senderMessageUid = "", avatar = "", name: senderName = "" } = message?.getSender() as unknown as { uid: string, avatar: string, name: string };
    const messageId = message?.getId();
    const timestampSentAt = convertTimestampToTime(message.getSentAt());
    const isMessageSentByCurrentUser = checkIsMessageSentByCurrentUser(senderMessageUid, currentUserLoginUid);

    useEffect(() => {
        // Get all Reaction of this message
        const getReactionsByMessage = async () => {
            const listReaction = await fetchListReactionByMessage(messageId);
            const listReactionRaw = listReaction as unknown as IReaction[];
            setCurrentListReaction(summarizeReactions(listReaction, currentUserLoginUid));
            setCurrentListReactionRaw(listReactionRaw)
        }
        getReactionsByMessage();

        subscription = emitterEvents.addListener('messageIdReaction', async (messageIdReaction: string) => {
            if (messageIdReaction) {
                if (messageId.toString() !== messageIdReaction) return;
                const listReaction = await fetchListReactionByMessage(Number(messageIdReaction));
                const listReactionRaw = listReaction as unknown as IReaction[];
                setCurrentListReaction(summarizeReactions(listReaction, currentUserLoginUid));
                setCurrentListReactionRaw(listReactionRaw)
            }
        })

        return () => {
            if (subscription) {
                subscription.remove()
            }
        }
    }, [])

    const handleRenderMessageByType = (message: CometChat.BaseMessage, typeTemplate: string) => {
        let messageRenderer = null;
        switch (typeTemplate) {
            case CometChatUIKitConstants.MessageTypes.text:
                messageRenderer = <MessageTextRenderer message={message} />
                break;
            case CometChatUIKitConstants.MessageTypes.delete:
                messageRenderer = <MessageDeletedTextRenderer message={message} />
                break;
            case CometChatUIKitConstants.MessageTypes.image:
                messageRenderer = <MessageImageRenderer message={message} />
                break;
            case CometChatUIKitConstants.MessageTypes.file:
                messageRenderer = <MessageFileRenderer message={message} />
                break;
            default:
                break;
        }
        return messageRenderer;
    }

    const handleAddReactionToMessage = async (messageId: number, emoji: string) => {
        try {
            await addReactionToMessage(messageId, emoji);
            const listReaction = await fetchListReactionByMessage(messageId);
            const listReactionRaw = listReaction as unknown as IReaction[];
            setCurrentListReaction(summarizeReactions(listReaction, currentUserLoginUid));
            setCurrentListReactionRaw(listReactionRaw)
        }
        catch (error) {
            console.error("Send reaction fail", error)
        }
    }

    const handleRemoveReactionToMessage = async (messageId: number, emoji: string) => {
        try {
            await removeReactionToMessage(messageId, emoji);
            const listReaction = await fetchListReactionByMessage(messageId);
            const listReactionRaw = listReaction as unknown as IReaction[];
            setCurrentListReaction(summarizeReactions(listReaction, currentUserLoginUid));
            setCurrentListReactionRaw(listReactionRaw)
        }
        catch (error) {
            console.error("Send reaction fail", error)
        }
    }

    const handleMouseEnter = () => {
        setIsMouseOver(true);
    };

    const handleMouseLeave = () => {
        setIsMouseOver(false);
    };

    const handleOpenPopOver = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
        setIsOpenPopOver(true);
    }

    const handleClosePopOver = () => {
        setAnchorEl(null);
        setIsOpenPopOver(false);
    }

    const handleOptionClick = async (labelOption: string) => {
        switch (labelOption) {
            case "Copy": {
                const { text = "" } = message as unknown as { text: string }
                await navigator.clipboard.writeText(text)
                break;
            }
            case "Delete": {
                setIsOpenModalConfirmDeleteMessage(true)
                break;
            }
        }
        setIsOpenPopOver(false)
    }

    const handleConfirmDeleteMessage = async () => {
        await deleteMessage(messageId.toString())
        onRefreshMessageList && onRefreshMessageList();
    }

    return (
        categories === CometChatUIKitConstants.MessageCategory.action
            ? <MessageActionMemberRenderer message={message} categories={categories} />
            : <CometChatBubbleViewTemplateStyled checked={checked}>
                <Box className={`messageReactionContainer  ${clsx({
                    'user-message': isMessageSentByCurrentUser,
                    'other-message': !isMessageSentByCurrentUser,
                })}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    {
                        <Box className={`menuOption ${clsx((isMouseOver || isOpenPopOver) && "showBlock")}`}>
                            <img src={menuOptionMessage} height={16} width={16} onClick={handleOpenPopOver} />
                        </Box>
                    }
                    <Box className={`messageContainer`}>
                        {!isMessageSentByCurrentUser && <img style={{ borderRadius: "30px" }} src={avatar || profile} width={30} height={30} />}
                        <Box className={`contentMessage`}>
                            <Typography component="p" className="senderName" style={{ fontFamily: "Manrope", fontWeight: 700, fontSize: 14 }}>
                                {senderName}
                            </Typography>
                            {handleRenderMessageByType(message, typeTemplate)}
                            <Box className="messageSentAt">
                                <span style={{ color: checked ? "#222222" : "#FFFFFF" }}>{timestampSentAt}</span>
                            </Box>
                        </Box>
                    </Box>
                    <CometChatBubbleViewReaction
                        messageId={messageId}
                        isMouseOver={isMouseOver}
                        onAddReactionToMessage={handleAddReactionToMessage}
                    />
                </Box>
                <PopOverOptionMessageStyled
                    open={isOpenPopOver}
                    anchorEl={anchorEl}
                    onClose={handleClosePopOver}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: isMessageSentByCurrentUser ? "left" : "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: isMessageSentByCurrentUser ? "right" : "left",
                    }}
                >
                    <MessageOptionItem
                        isMessageSentByCurrentUser={isMessageSentByCurrentUser}
                        onOptionClick={handleOptionClick}
                    />
                </PopOverOptionMessageStyled>
                <CometChatBubbleViewListReaction
                    checked={checked}
                    message={message}
                    listReaction={currentListReaction}
                    listReactionRaw={currentListReactionRaw}
                    onRemoveReactionToMessage={handleRemoveReactionToMessage}
                    isMessageSentByCurrentUser={isMessageSentByCurrentUser}
                    onOpenModalTotalReaction={onOpenModalTotalReaction}
                />

                <DeleteMessageModal
                    data-testid="deleteMessageModal"
                    isOpen={isOpenModalConfirmDeleteMessage}
                    checked={!checked}
                    onSubmit={() => { handleConfirmDeleteMessage() }}
                    onClose={() => { setIsOpenModalConfirmDeleteMessage(false) }}
                />
            </CometChatBubbleViewTemplateStyled>
    )
}

export function summarizeReactions(dataArray: ReactionItem[], myUid: string): ReactionObject[] {
    const summaryMap: Record<string, { count: number; reactedByMe: boolean }> = {};

    dataArray.forEach((item) => {
        const reaction = item.reaction;

        if (!summaryMap[reaction]) {
            summaryMap[reaction] = {
                count: 0,
                reactedByMe: false
            };
        }

        summaryMap[reaction].count += 1;
        if (item.uid === myUid) {
            summaryMap[reaction].reactedByMe = true;
        }
    });

    // Convert the summaryMap into an array of ReactionSummaryItem
    const summary: ReactionObject[] = Object.entries(summaryMap).map(([reaction, { count, reactedByMe }]) => ({
        reaction,
        count,
        reactedByMe
    }));

    return summary;
}

const checkIsMessageSentByCurrentUser = (senderUidMessage: string, currentUserLoginUid: string) => {
    return currentUserLoginUid === senderUidMessage;
}

const MessageTextRenderer = ({ message }: IPropMessageRenderer) => {
    const getDataMessage = message.getData();
    const { text } = getDataMessage as unknown as { text: string };
    return <Typography className="messageTextContent" component={"p"}>
        {text}
    </Typography>
}

const MessageDeletedTextRenderer = ({ message }: IPropMessageRenderer) => {
    return <Typography className="deletedMessageContent" component={"p"}>
        {"This message has been deleted"}
    </Typography>
}

const MessageImageRenderer = ({ message }: IPropMessageRenderer) => {
    const getDataMessage = message.getData();
    const { url } = getDataMessage as unknown as { url: string };
    return (
        <Box>
            <img src={url} alt={"i"} style={{
                maxWidth: "100%",
                maxHeight: "100%",
                display: "block",
                margin: "auto"
            }} />
        </Box>
    )
}

const MessageFileRenderer = ({ message }: IPropMessageRenderer) => {
    const getDataMessage = message.getData();
    const { attachments = [] } = getDataMessage;
    const getAttachment: { name: string, url: string } = attachments.find((attachment: { name: string, url: string }) => attachment.name);
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = getAttachment.url;
        link.download = getAttachment.name;
        link.dispatchEvent(new MouseEvent('click'));
    };
    return (
        <Box>
            <p className="messageTextContent">{getAttachment.name}</p>
            <Button className="buttonDownloadFile" onClick={handleDownload}>
                Download
            </Button>
        </Box>
    )
}

const MessageActionMemberRenderer = ({ message, categories = "" }: IPropMessageRenderer) => {
    const castMessage = message as unknown as { message: string };
    return (
        <MessageActionRendererStyled>
            <p className="messageTextAction">{castMessage.message}</p>
        </MessageActionRendererStyled>
    )
}

const MessageActionRendererStyled = styled(Box)({
    padding: "6px 10px",
    background: "#7575751F",
    borderRadius: "6px",
    "& .messageTextAction": {
        fontFamily: "Manrope",
        fontWeight: 500,
        fontSize: "10px",
        color: "#757575"
    }
});

const CometChatBubbleViewTemplateStyled = styled(Box)(({ checked }: { checked?: boolean }) => ({
    "& .messageReactionContainer": {
        display: "flex",
        position: "relative",
        alignItems: "center",
        "& .menuOption": {
            visibility: "hidden"
        },
        "& .menuOption.showBlock": {
            visibility: "unset"
        }
    },
    "& .messageReactionContainer.user-message": {
        "& .messageContainer": {
            marginLeft: "12px",
        },
        "& .contentMessage": {
            background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)"
        }
    },

    "& .messageReactionContainer.other-message": {
        flexDirection: "row-reverse",
        "& .messageContainer": {
            marginRight: "12px",
        },
        "&  .contentMessage": {
            "& .messageTextContent": {
                color: checked ? "#222222" : "#FFFFFF",
            },
            "& .senderName": {
                color: checked ? "#222222" : "#FFFFFF",
            },
            "& .messageSentAt": {
                marginRight: "calc(12px + 16px)"
            },
            background: checked ? "#E9E9E9" : "#222222"
        }
    },
    "& .messageContainer": {
        display: "flex",
        gap: 5,
        width: "350px",
        "& .contentMessage": {
            width: "100%",
            boxSizing: "border-box",
            minHeight: 60,
            padding: "12px 20px",
            paddingRight: "36px",
            borderRadius: 16,
            postion: "relative",
            "& .messageTextContent": {
                wordBreak: "break-all",
                whiteSpace: "normal",
                fontFamily: "Manrope",
                fontWeight: 500,
                fontSize: 14,
                color: "#FFFFFF"
            },
            "& .deletedMessageContent": {
                fontStyle: "italic",
            },
            "& .buttonDownloadFile": {
                padding: "6px 0"
            },
            "& .messageSentAt": {
                position: "absolute",
                height: 15,
                bottom: 14,
                right: "calc(36px - 28px)",
                width: "28px",
                textAlign: "right",
                color: "#FFFFFF",
                fontFamily: "Manrope",
                fontWeight: 500,
                fontSize: "10px"
            },
            "& .senderName": {
                color: "#FFFFFF",
            }
        },
    },
}));


const PopOverOptionMessageStyled = styled(Popover)(() => ({
    borderRadius: 5
}));

export default CometChatBubbleViewTemplate;