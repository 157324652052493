import { Box, styled, Typography } from '@material-ui/core';
import React from 'react';

interface OptionItem {
    label: string;
    className?: string;
}

const baseOptions: OptionItem[] = [
    { label: 'Reply' },
    { label: 'Copy' },
    { label: 'Forward' },
];

interface MessageOptionItemProps {
    isMessageSentByCurrentUser: boolean;
    onOptionClick?: (optionLabel: string) => void;
}

const MessageOptionItem: React.FC<MessageOptionItemProps> = ({ isMessageSentByCurrentUser, onOptionClick }) => {
    const options = isMessageSentByCurrentUser
        ? [...baseOptions, { label: 'Delete', className: 'deleteBtn' }]
        : baseOptions;

    return (
        <MenuOptionsStyled>
            <Box className="listOption">
                {options.map((option) => (
                    <Box
                        key={option.label}
                        className={`itemOption ${option.className || ''}`}
                        onClick={() => onOptionClick && onOptionClick(option.label)}
                    >
                        <Typography component="p">{option.label}</Typography>
                    </Box>
                ))}
            </Box>
        </MenuOptionsStyled>
    );
};

export default MessageOptionItem;


const MenuOptionsStyled = styled(Box)(() => ({
    borderRadius: 5,
    "& .listOption": {
        display: "flex",
        flexDirection: "column",
        padding: "10px 0",
        background: "#222222",
        borderRadius: 5,
        "& .itemOption": {
            width: 150,
            height: 36,
            padding: "9px 20px 9px 16px",
            boxSizing: "border-box",
            cursor: "pointer",
            "& p": {
                fontFamily: "Manrope",
                fontWeight: 500,
                fontSize: 14,
                color: "#FFFFFF"
            },
        },
        "& .itemOption.deleteBtn p": {
            color: "#DC2626"
        }
    }
}));
