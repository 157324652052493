import React from "react";
// Customizable Area Start
import {
  Typography,
  Paper,
  Box,
  Grid,
  Avatar,
  Button,
  Card,
  CardContent,
  IconButton,
  styled
} from "@material-ui/core";
import Loader from '../../../components/src/Loader.web'


import { createTheme,ThemeProvider, withStyles} from "@material-ui/core/styles";
import {darkThemeBg,lightThemeBg,radioButtonChecked,radioButtonUncheck,darkView,view, RightIcon} from "./assets";

const StyledIcon = styled(IconButton)({
  padding:0,
  marginLeft:-4,
  "&.MuiIconButton-root:hover":{
   background:'unset'
  }
})
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
    },
    typography: {
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        margin: "20px 0px",
      },
    },
  });
  const lightTheme = createTheme({
    palette: {
      type: "light",
      primary: {
        main: "#3858E3",
      },
      secondary:{
        main:'rgba(0,0,0,0.75)'
      }
     }
    });
  
  const darkTheme=createTheme({
      palette: {
        type: "dark",
        primary: {
          main: "#3858E3",
        },
        secondary:{
        main:"rgba(255,255,255,0.75)"
        }
       }
      })

// Customizable Area End

import MySubscriptionsController, {
  Props,
  configJSON,
} from "./MySubscriptionsController.web";
import { CongratsDialogModal } from "../../../components/src/DialogModal";
import {OrderSummary} from "./OrderSummary.web";

export default class MySubscriptions extends MySubscriptionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  renderSubScreen() {
    const {classes}=this.props
    const classStyles = classes ? classes : {}
    return (  <Grid container className={classStyles.gridContainer}>
      <Grid item className={classStyles.imageGridItem} md={5} sm={6} lg={6}>
      </Grid>
      <Grid item xs={10} sm={5} md={5} lg={5} className={classStyles.gridItem}>
        <Box className={classStyles.formBox}>
          <Avatar className={this.props.checked ? classStyles.avatarLightTheme : classStyles.avatar} data-test-id='backbuttonOtp'>
          <IconButton style={{borderRadius:'unset',height:'100%',width:"100%"}} disableRipple disableFocusRipple disableTouchRipple> 
            <img src = {this.props.checked?darkView:view} onClick={()=> this.props.navigation.goBack()}/>
         </IconButton>
          </Avatar>
          <Typography data-test-id='heading' className={classStyles.subscriptionHeading}>{configJSON.chooseSubscription}</Typography>
          <Typography className={classStyles.subscriptionDescription}>
            {configJSON.subscriptionDescription}
          </Typography>
          <Box className={classStyles.cardsContainer}>
            {this.state.subscriptionDetails.map((subscription) =>
              <Card variant="outlined" key={subscription.id} className={subscription.attributes.subscribed ? classStyles.activeCard : classStyles.card} onClick={() => this.handleSelectSubscription(subscription.id)}>
                <CardContent>
                  <img data-test-id={`radioButton${subscription.id}`} className={classStyles.radioImage} src={subscription.attributes.subscribed ? radioButtonChecked : radioButtonUncheck} />
                  <Typography data-test-id='names' className={classStyles.subscriptionName}>{subscription.attributes.title}</Typography>
                  <Typography className={classStyles.subscriptionPrice}>${subscription.attributes.price}/{subscription.attributes.period}</Typography>
                  <Typography className={classStyles.description}>{subscription.attributes.details}</Typography>
                
                </CardContent>
              </Card>
            )}

          </Box>
          <Button
            onClick={this.handleSubsctiption}
            data-test-id='submit'
            disabled={this.state.activeId === 0}
            className={classStyles.button}
            fullWidth
            endIcon={<StyledIcon><img src={RightIcon} /></StyledIcon>}
          >{configJSON.subscribe}
          </Button>
        </Box>

      </Grid>
    </Grid>)
  }
  // Customizable Area End

  render() {
      // Customizable Area Start
      const {classes}=this.props
      const classStyles = classes ? classes : {}
      // Customizable Area End
    return (
      // Customizable Area Start
      <>
      {this.state.isSubscribe ? <MyOrderSummary id="" navigation={this.props.navigation} 
      activeId={this.state.activeId}
      checked={false}
      handleSelectSubscription={this.handleSelectSubscription}
      subscriptionDetails={this.state.subscriptionDetails}
      backToSubscribePage={this.backToSubscribePage}
      onPromoCodeChange={this.onPromoCodeChange}
      promoCode={this.state.promoCode}
      applyPromoCode={this.applyPromoCode}
      promoCodeDetail={this.state.promoCodeDetail}
      subscribeUserAPI={this.subscribeUserAPI}
      getActiveSub = {this.getActiveSub}
      backToSubscriptionList={this.backToSubscribePage}
      orderSummary={this.state.orderSummary}
      isautoRenawal={this.state.isAutoRenewal}
      autoRenewal={this.autoRenewal}
      /> :( <ThemeProvider theme={this.props.checked ? lightTheme : darkTheme}>
        <Paper className={this.props.checked ? classStyles.thinColor : classStyles.color}>
          <Box className={!this.props.checked ? classStyles.imageBackground : classStyles.thinBackground}>
            <Loader loading={false} />
            {this.renderSubScreen()}
            {this.state.isSubscriptionDone && <CongratsDialogModal navigaion={this.props.navigation} />}
          </Box>
        </Paper>
     </ThemeProvider>)}
     </>      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  color:{
    backgroundColor:'#070707'
  },
  activeCard: {
    backgroundColor: '#2121210A',
    marginTop: '15px',
    borderRadius: '10px',
    position: 'relative' as 'relative',
    border: '1px solid #8833FF',
    backdropFilter:'blur(12px)'

  },
  cardsContainer: {
    overflow: 'auto',
    marginTop:'15px'
  },
  addPaymentContainer:{
    display:'flex',
    justifyContent:'space-between',

  },
  addNewBtn:{
    borderRadius:'10px',
    border: "1px solid #8833FF",
    padding:'4px',
    textTransform:'none'
  },
  

  radioImage: {
    position: 'absolute' as 'absolute',
    right: '3%',
    cursor: 'pointer'
  },
  optionContainer: {
    display: 'flex',
    gap: '7px',
    marginTop: '10px'
  },

  feature: {
    fontFamily: "Manrope",
    fontSize: "12px",
    fontStyle: "normal" as "normal",
    fontWeight: 500,
  },
  paymentName: {
    fontFamily: "Manrope",
    fontSize: "18px",
    fontStyle: "normal" as "normal",
    fontWeight: 500,
  },
  promoCodeContainer:{
    display:'flex',
    gap:'16px',
    alignItems:'center',
    justifyContent:'space-between'
  },
  promoInput:{
    border:'none',
    color:'white',
    fontSize:'14px',
    fontFamily: "Manrope",
    fontStyle: "normal" as "normal",
    fontWeight: 500,
  },
  applyBtn:{
    border: '1px solid black',
    borderRadius:'5px',
    color:'#FFFF',
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    fontSize:'12px',
    fontWeight:700
  },
  subscriptionName: {
    fontFamily: "Manrope",
    fontSize: "18px",
    fontStyle: "normal" as "normal",
    fontWeight: 800,
  },
  subscriptionPrice: {
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as "normal",
    fontWeight: 500,
  },
  paymentMethodName: {
    color:"#4E4E4E"
  },
  paymentCardContainer:{
    display:'flex',
    gap:'16px',
    alignItems:'center'
  },
  description: {
    fontFamily: "Manrope",
    fontSize: "10px",
    fontStyle: "normal" as "normal",
    fontWeight: 500,
    color: '#999999',
    marginTop:'8px'

  },

  card: {
    backgroundColor: '#2121210A',
    marginTop: '15px',
    borderRadius: '10px',
    backdropFilter:'blur(12px)',
    position: 'relative' as 'relative'
  },
  subscriptionDescription: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontStyle: "normal" as "normal",
    fontWeight: 600,
    marginTop:'5px'
  },
  subscriptionHeading: {
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    fontFamily: "Manrope",
    fontSize: "31px",
    fontStyle: "normal" as "normal",
    fontWeight: 800,
    WebkitTextFillColor: "transparent",
    WebkitBackgroundClip: "text",
    backgroundClip: "text",
    lineHeight:'32px',
    marginTop:'30px',
    letterSpacing:'-0.005em'

  },
  button: {
    borderRadius: "10px",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    textTransform: "capitalize" as "capitalize",
    color: "#FFF",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    marginTop: '35px',
    height: '46px',
    "&.MuiButton-root.Mui-disabled": {
      color: '#E9E9E9',
      opacity:0.64
    }
  },
  backIcon: {
    color: '#fff',
    fontSize: '30px'
  },
  backIconLightTheme: {
    color: '#222222',
    fontSize: '30px'
  },
  formBox: {
    marginTop:'20px',
    maxWidth:"445px",
    width:"95%"
  },
  thinColor: {
    backgroundColor: '#fff'
  },
  imageBackground: {
    backgroundImage: `url(${darkThemeBg})`,
    height: "100vh",
    width: "100%",
    overflow: "auto",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: 'center',
    backgroundAttachment: "scroll",
    [theme.breakpoints.down('xs')]:
      { backgroundImage: 'none' }
  },
  thinBackground: {
    backgroundImage: `url(${lightThemeBg})`,
    height: "100vh",
    width: "100%",
    overflow: "auto",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: 'center',
    backgroundAttachment: "scroll",
    [theme.breakpoints.down('xs')]:
      { backgroundImage: 'none' }
  },
  gridContainer: {
    justifyContent: 'space-between' as 'space-between',
    alignItems: 'center',
    height: '90vh',
    paddingTop: "3rem",
    [theme.breakpoints.down('xs')]:
      { justifyContent: 'center' as 'center' }
  },
  imageGridItem: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: { display: "none" },
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center'
  },
  avatarLightTheme: {
    borderRadius: "8px",
    backgroundColor: '#E9E9E9',
    cursor: 'pointer'
  },
  avatar: {
    borderRadius: "8px",
    backgroundColor: '#222222',
    cursor: 'pointer'
  },
  paymentContainer:{
    display:'flex',
    justifyContent:'space-between',
    gap:'12px'
  },
  paymentText:{
    font:'Inter',
    fontSize:'12px',
    fontWeight:500
  },
}
export const MySubscription = withStyles(styles as any)(MySubscriptions);
const MyOrderSummary = withStyles(styles as any)(OrderSummary as any)
// Customizable Area End
