import React, { Fragment } from "react";
import { Box, styled } from "@material-ui/core";
import { IReaction, ReactionObject } from "./CometChat";
import clsx from "clsx";
const heartReaction = require("./assets/heartReaction.svg");
const smileReaction = require("./assets/smileReaction.svg");
const sadReaction = require("./assets/sadReaction.svg");
const likeReaction = require("./assets/likeReaction.svg");
const dislikeReaction = require("./assets/dislikeReaction.svg");

export const LIST_REACTION = [
    { value: "HEART", icon: heartReaction },
    { value: "SMILE", icon: smileReaction },
    { value: "SAD", icon: sadReaction },
    { value: "LIKE", icon: likeReaction },
    { value: "DISLIKE", icon: dislikeReaction },
]

interface IPropCometChatBubbleViewListReaction {
    message: CometChat.BaseMessage;
    listReaction: ReactionObject[];
    listReactionRaw: IReaction[];
    isMessageSentByCurrentUser: boolean;
    checked?: boolean;
    onRemoveReactionToMessage: (messageId: number, emoji: string) => Promise<void>;
    onOpenModalTotalReaction?: (listReaction: IReaction[]) => void;
}

interface IPropReactionListComponent {
    checked?: boolean;
    messageId: number,
    listReaction: ReactionObject[],
    isMessageSentByCurrentUser: boolean;
    onRemoveReactionToMessage: (messageId: number, emoji: string) => Promise<void>;
}

export default function CometChatBubbleViewListReaction({
    message,
    isMessageSentByCurrentUser,
    listReaction = [],
    listReactionRaw = [],
    checked,
    onRemoveReactionToMessage,
    onOpenModalTotalReaction
}: IPropCometChatBubbleViewListReaction) {
    const getDataMessage = message?.getData();
    if (!getDataMessage) return <Fragment></Fragment>
    const messageId = message?.getId();

    const renderTotalReaction = () => {
        const totalReactions = listReaction.reduce((accumulator, currentReaction) => {
            return accumulator + currentReaction.count;
        }, 0);

        return !!totalReactions && <Box
            style={{ padding: "0 4px" }}
            onClick={() => onOpenModalTotalReaction && onOpenModalTotalReaction(listReactionRaw || [])}
        >
            <p className="totalReaction__text">{totalReactions}</p>
        </Box>
    }

    return (
        <CometChatBubbleViewListReactionStyled
            checked={checked}
            ismessagesentbycurrentuser={isMessageSentByCurrentUser.toString()}>
            {
                !!listReaction.length && <ReactionListComponent
                    checked={checked}
                    isMessageSentByCurrentUser={isMessageSentByCurrentUser}
                    messageId={messageId}
                    listReaction={listReaction}
                    onRemoveReactionToMessage={onRemoveReactionToMessage}
                />
            }
            {renderTotalReaction()}
        </CometChatBubbleViewListReactionStyled>
    )
}

const ReactionListComponent = ({
    messageId,
    listReaction = [],
    onRemoveReactionToMessage,
    checked,
}: IPropReactionListComponent) => {

    return (<ReactionListComponentStyled checked={checked}>
        {
            transformListReaction(listReaction).map((reaction) => {
                const castReaction = reaction as unknown as ReactionObject;
                const { reactedByMe } = castReaction;
                return <Box key={reaction.value} className={`reactionItem ${clsx(reactedByMe && "reactionItem-active")}`} onClick={() => { onRemoveReactionToMessage(messageId, reaction.value) }}>
                    <img alt="r" src={reaction.icon} width={16} height={16} />
                    <span className="countReaction">{reaction.count}</span>
                </Box>

            })
        }
    </ReactionListComponentStyled>)

}

const CometChatBubbleViewListReactionStyled = styled(Box)(({ ismessagesentbycurrentuser, checked }: { ismessagesentbycurrentuser: string, checked?: boolean }) => ({
    width: "fit-content",
    background: checked ? "#E9E9E9" : "#222222",
    borderRadius: "10px",
    marginLeft: ismessagesentbycurrentuser === "true" ? 28 : 35,
    display: "flex",
    alignItems: "center",
    padding: "0px 4px",
    gap: 8,
    "& .totalReaction__text": {
        cursor: "pointer",
        fontFamily: "Manrope",
        fontWeight: 500,
        fontSize: "14px",
        color: checked ? "#222222" : "#FFFFFF"
    }
}));

const ReactionListComponentStyled = styled(Box)(({ checked }: { checked?: boolean }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 10,
    padding: 4,
    cursor: "pointer",
    "& .reactionItem": {
        display: "flex",
        alignItems: "center",
        gap: 4,
        padding: "2px 4px",
        border: "2px solid transparent",
        "& .countReaction": {
            fontFamily: "Manrope",
            fontWeight: 500,
            fontSize: 14,
            color: checked ? "#1F1F1F" : "#E9E9E9"
        }
    },
    "& .reactionItem.reactionItem-active": {
        border: "2px solid #4a4ab3",
        borderRadius: 16,
    },
}));

export const transformListReaction = (listReaction: ReactionObject[]) => {
    const listReactionMap = new Map(
        listReaction.map((reactionObj) => [reactionObj.reaction, reactionObj])
    );
    return LIST_REACTION
        .filter((reaction) => listReactionMap.has(reaction.value))
        .map((reaction) => {
            const objListReaction = listReactionMap.get(reaction.value);
            if (!objListReaction) return { ...reaction, count: 0 };
            return {
                ...reaction,
                ...objListReaction,
            };
        });
}
